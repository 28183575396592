<template>
  <div>
    <div class="top-row">
      <div class="tabs">
        <div :class="['tab', tabIndex==index ? 'active':'']" v-for="(item, index) in pubManageStates" :key="index"
        @click="tabChange(index)">{{item.statusTitle}}({{item.num}})</div>
      </div>
      <div class="btn-publish btn-fill-theme" @click="$router.push('/publishManage/publishTask')">
        <img src="@/assets/images/icon-fly.png">
        <span>发布任务</span>
      </div>
    </div>
    <div class="flex justify-between items-center mt16">
      <img class="btn-refresh" src="@/assets/images/icon-refresh.png" @click="getManageData">
      <div class="search">
        <el-input placeholder="搜索任务名称" clearable v-model="searchWard" @clear="searchClear"></el-input>
        <div class="btn-search btn-fill-theme" @click="searchTask">
          <img src="@/assets/images/icon-search.png">
        </div>
      </div>
    </div>
    <TaskList v-loading="loading" v-if="taskList && taskList.length > 0" :list="taskList" @update="getManageData"></TaskList>
    <div v-else class="panel mt16">
      <Empty/>
    </div>
    <div class="flex justify-end mt24">
      <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
    </div>
  </div>
</template>

<script>
import TaskList from '@/components/TaskList'
import Empty from '@/components/Empty'
import Pagination from '@/components/Pagination'
import { merchantManageApi, merchantPubListApi } from '@/api/merchant.js'
import { searchApi } from '@/api/task.js'
export default {
  name: 'List',
  components: {
    TaskList,
    Empty,
    Pagination
  },
  data() {
    return {
      searchWard: '',
      pubManageStates: [],
      taskList: [],
      tabIndex: 0,
      curPage: 0,
      loading: false
    }
  },
  created() {
    this.getManageData()
  },
  methods: {
    getManageData() {
      merchantManageApi().then(res => {
        console.log('列表数据', res)
        this.pubManageStates = res.data.pubManageStates
        this.getTaskList() // 已上架
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    getTaskList() {
      this.loading = true
      merchantPubListApi({
        'starPage': this.curPage,
				'statues': this.pubManageStates[this.tabIndex].compositeStatus
      }).then(res => {
        this.loading = false
        console.log('任务列表', res)
        const list = res.data.pubs || []
        if(list.length > 0 || this.curPage == 0) {
          this.taskList = list
        } else {
          this.$tips({type: 'warning', message: '没有更多数据了'})
          this.curPage--
        }
        
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    searchTask() {
      // if(!this.searchWard.trim()) return this.searchClear()
      // searchApi({
      //   'searchId': this.searchWard,
      //   'startPage': this.curPage
      // }).then(res => {
      //   console.log('搜索任务', res)
      //   const list = res.data.taskListItems || []
      //   if(list.length > 0 || this.curPage == 0) {
      //     this.taskList = list
      //   } else {
      //     this.$tips({type: 'warning', message: '没有更多数据了'})
      //     this.curPage--
      //   }
      // }).catch(err => {
      //   this.$tips({type: 'error', message: err})
      // })
    },
    searchClear() {
      // this.curPage = 0
      // this.getTaskList()
    },
    tabChange(index) {
      this.curPage = 0
      this.tabIndex = index
      this.searchWard = ''
      this.getTaskList()
    },
    nextPage() {
      this.curPage++
      // !this.searchWard.trim() ? this.getTaskList() : this.searchTask()
      this.getTaskList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      // !this.searchWard.trim() ? this.getTaskList() : this.searchTask()
      this.getTaskList()
    }
  }
}
</script>

<style lang="scss" scoped>
.top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tabs {
    display: flex;
    align-items: center;
    .tab {
      position: relative;
      margin-right: 24px;
      padding: 0 13px;
      font-size: 16px;
      cursor: pointer;
    }
    .active {
      color: var(--theme-color);
      &::after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 96px;
        height: 2px;
        border-radius: 2px;
        background-color: var(--theme-color);
      }
    }
  }
  .btn-publish {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 234px;
    height: 46px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
.btn-refresh {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.search {
  display: flex;
  width: 240px;
  .btn-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
}


</style>